import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="container">
      <br />
      <br />
      <p style={{ whiteSpace: "pre-wrap" }}>
        At Finstead Risk Solutions Pty Ltd, we are committed to protecting your
        privacy in accordance with the Privacy Act 1988 (Cth) and the Australian
        Privacy Principles. This Privacy Policy describes our current policies
        and practices in relation to the collection, handling, use and
        disclosure of personal information. It also deals with how you can
        complain about a breach of the privacy laws and how you can access the
        personal information we hold and how to have that information corrected.{" "}
      </p>
      <h3 style={{ whiteSpace: "pre-wrap" }}>
        <strong>What information do we collect and how do we use it?</strong>
      </h3>
      <p style={{ whiteSpace: "pre-wrap" }}>
        When we arrange insurance on your behalf, we ask you for the information
        we need to advise you about your insurance needs and management of your
        risks. This can include a broad range of information ranging from your
        name, address, contact details, age to other information about your
        personal affairs including your assets, personal belongings, financial
        situation, health and wellbeing. We provide any information that the
        insurers or intermediaries who we ask to quote for your insurances and
        premium funding require to enable them to decide whether to insure you
        and on what terms or to fund your premium and on what terms.{" "}
      </p>
      <p style={{ whiteSpace: "pre-wrap" }}>
        Insurers may in turn pass on this information to their reinsurers. Some
        of these companies are located outside Australia. For example, if we
        seek insurance terms from an overseas insurer (e.g. Lloyd’s of London),
        your personal information may be disclosed to the insurer. If this is
        likely to happen, we inform you of where the insurer is located, if it
        is possible to do so.{" "}
      </p>
      <p style={{ whiteSpace: "pre-wrap" }}>
        When you make a claim under your policy, we assist you by collecting
        information about your claim. Sometimes we also need to collect
        information about you from others. We provide this information to your
        insurer (or anyone your insurer has appointed to assist it to consider
        your claim, eg loss adjusters, medical brokers etc) to enable it to
        consider your claim. Again this information may be passed on to
        reinsurers.{" "}
      </p>
      <p style={{ whiteSpace: "pre-wrap" }}>
        From time to time, we will use your contact details to send you direct
        marketing communications including offers, updates and newsletters that
        are relevant to the services we provide. We always give you the option
        of electing not to receive these communications in the future. You can
        unsubscribe by notifying us and we will no longer send this information
        to you.{" "}
      </p>
      <h3 style={{ whiteSpace: "pre-wrap" }}>
        <strong>What if you don’t provide some information to us?</strong>
      </h3>
      <p style={{ whiteSpace: "pre-wrap" }}>
        We can only fully advise you and assist in arranging your insurance or
        with a claim if we have all relevant information. The insurance laws
        also require you to provide your insurers with the information they need
        in order to be able to decide whether to insure you and on what terms.
        You have a duty to disclose the information which is relevant to the
        insurer’s decision to insure you.{" "}
      </p>
      <h3 style={{ whiteSpace: "pre-wrap" }}>
        <strong>When do we disclose your information overseas?</strong>
      </h3>
      <p style={{ whiteSpace: "pre-wrap" }}>
        If you ask us to seek insurance terms and we recommend an overseas
        insurer, we may be required to disclose the information to the insurer
        located outside Australia. For example, if we recommend a policy
        provided by Lloyd’s of London, your information may be given to the
        Lloyd’s broker and underwriters at Lloyd’s of London to make a decision
        about whether to insure you.{" "}
      </p>
      <p style={{ whiteSpace: "pre-wrap" }}>
        We will tell you at time of advising on your insurance if they are
        overseas and in which country the insurer is located. If the insurer is
        not regulated by laws which protects your information in a way that is
        similar to the Privacy Act, we will seek your consent before disclosing
        your information to that insurer.{" "}
      </p>
      <p style={{ whiteSpace: "pre-wrap" }}>
        Australian and overseas insurers acquire reinsurance from reinsurance
        companies that are located throughout the world so in some cases your
        information may be disclosed to them for assessment of risks and in
        order to provide reinsurance to your insurer. We do not make this
        disclosure. This is made by the insurer (if necessary) for the placement
        for their reinsurance program.{" "}
      </p>
      <p style={{ whiteSpace: "pre-wrap" }}>
        We may also disclose information we collect to the providers of our
        policy administration and broking systems that help us to provide our
        products and services to you. These policy administration providers and
        broking systems may be supported and maintained by organisations in New
        Zealand, the Philippines and Vietnam and your information may be
        disclosed to those organisations. Please note that the Privacy Act and
        Australian Privacy Principles may not apply to these organisations.{" "}
      </p>
      <h3 style={{ whiteSpace: "pre-wrap" }}>
        <strong>How do we hold and protect your information?</strong>
      </h3>

      <p style={{ whiteSpace: "pre-wrap" }}>
        We strive to maintain the reliability, accuracy, completeness, and
        currency of the personal information we hold and to protect its privacy
        and security. We keep personal information only for as long as is
        reasonably necessary for the purpose for which it was collected or to
        comply with any applicable legal or ethical reporting or document
        retention requirements.{" "}
      </p>
      <p style={{ whiteSpace: "pre-wrap" }}>
        We hold the information we collect from you initially in a working file,
        which when completed is electronically imaged and stored. After which
        any paper is destroyed in our onsite shredder. In some cases, your file
        is archived and sent to an external data storage provider for a period
        of time. We only use storage providers located in Australia who are also
        regulated by the Privacy Act.{" "}
      </p>
      <p style={{ whiteSpace: "pre-wrap" }}>
        We ensure that your information is safe by protecting it from
        unauthorised access, modification, and disclosure. We maintain physical
        security over our paper and electronic data and premises, by using locks
        and security systems. We also maintain computer and network security;
        for example, we use firewalls (security measures for the Internet) and
        other security systems such as user identifiers and passwords to control
        access to computer systems where your information is stored.{" "}
      </p>
      <h3 style={{ whiteSpace: "pre-wrap" }}>
        <strong>Will we disclose the information we collect to anyone?</strong>
      </h3>
      <p style={{ whiteSpace: "pre-wrap" }}>
        We do not sell, trade, or rent your personal information to others.{" "}
      </p>
      <p style={{ whiteSpace: "pre-wrap" }}>
        We may need to provide your information to contractors who supply
        services to us, e.g. to handle mailings on our behalf, external data
        storage providers or to other companies in the event of a corporate
        sale, merger, re-organisation, dissolution, or similar event. We may
        also disclose information we collect to the providers of our policy
        administration and broking systems that help us to provide our products
        and services to you. However, we will take reasonable measures to ensure
        that they protect your information as required under the Privacy Act.{" "}
      </p>
      <p style={{ whiteSpace: "pre-wrap" }}>
        We may provide your information to others if we are required to do so by
        law, you consent to the disclosure or under some unusual other
        circumstances which the Privacy Act permits.{" "}
      </p>
      <h3 style={{ whiteSpace: "pre-wrap" }}>
        <strong>
          How can you check, update or change the information we are holding?
        </strong>
      </h3>
      <p style={{ whiteSpace: "pre-wrap" }}>
        Upon receipt of your written request and sufficient information to allow
        us to identify the information, we will disclose to you the personal
        information we hold about you. We will also correct, amend, or delete
        any personal information that we agree is inaccurate, irrelevant, out of
        date or incomplete.{" "}
      </p>
      <p style={{ whiteSpace: "pre-wrap" }}>
        If you wish to access or correct your personal information, please write
        to James Sinclair, CEO at Finstead Risk Solutions Pty Ltd, Level 11, 56
        Pitt Street, Sydney NSW 2000.{" "}
      </p>
      <p style={{ whiteSpace: "pre-wrap" }}>
        We do not charge for receiving a request for access to personal
        information or for complying with a correction request. Where the
        information requested is not a straightforward issue and will involve a
        considerable amount of time then a charge will need to be confirmed for
        responding to the request for the information.{" "}
      </p>
      <p style={{ whiteSpace: "pre-wrap" }}>
        In some limited cases, we may need to refuse access to your information
        or refuse a request for correction. We will advise you as soon as
        possible after your request if this is the case and the reasons for our
        refusal.{" "}
      </p>
      <h3 style={{ whiteSpace: "pre-wrap" }}>
        <strong>What happens if you want to complain?</strong>
      </h3>
      <p style={{ whiteSpace: "pre-wrap" }}>
        If you have concerns about whether we have complied with the Privacy Act
        or this Privacy Policy when collecting or handling your personal
        information, please write to James Sinclair, CEO at Finstead Risk
        Solutions Pty Ltd. Level 11, 56 Pitt Street, Sydney NSW 2000.{" "}
      </p>
      <p style={{ whiteSpace: "pre-wrap" }}>
        Your complaint will be considered by us through our Internal Complaints
        Resolution Process. We will acknowledge your complaint within 24 hours
        and we will respond with a decision within 30 days of you making the
        complaint. If we need to investigate your complaint and require further
        time, we will work with you to agree to an appropriate timeframe to
        investigate. We will provide you with information concerning referring
        your complaint to the Office of the Australian Information Commissioner
        (OAIC) if we cannot resolve your complaint.{" "}
      </p>
      <h3 style={{ whiteSpace: "pre-wrap" }}>
        <strong>Your consent</strong>
      </h3>
      <p style={{ whiteSpace: "pre-wrap" }}>
        By asking us to assist with your insurance needs, you consent to the
        collection and use of the information you have provided to us for the
        purposes described above.{" "}
      </p>
      <h3 style={{ whiteSpace: "pre-wrap" }}>
        <strong>Website information and content.</strong>
      </h3>
      <p style={{ whiteSpace: "pre-wrap" }}>
        The information provided on this website does not cover all aspects of
        the law on the relevant subject matter. Professional advice should be
        sought before any action is taken based upon the matters described and
        discussed on this site.{" "}
      </p>

      <p style={{ whiteSpace: "pre-wrap" }}>
        To the extent permitted by law, we make no representations about the
        suitability of the content of this site for any purpose. All content is
        provided without any warranty of any kind. We disclaim all warranties
        and conditions with regard to the content, including but not limited to
        all implied warranties and conditions of fitness for a particular
        purpose, title and non-infringement.{" "}
      </p>
      <p style={{ whiteSpace: "pre-wrap" }}>
        We will not be liable for any damages or injury caused by, including but
        not limited to, any failure of performance, error, omission,
        interruption, defect, delay in operation of transmission, computer
        virus, or line failure. To the extent permitted by law we will not be
        liable for any damages or injury, including but not limited to, special
        or consequential damages that result from the use of, or the inability
        to use, the materials in this site.{" "}
      </p>
      <p style={{ whiteSpace: "pre-wrap" }}>
        We believe the content of this site to be accurate, complete and
        current, however there are no warranties as to the accuracy,
        completeness or currency of the content. It is your responsibility to
        verify any information before relying on it. The content of this site
        may include technical inaccuracies or typographical errors.{" "}
      </p>
      <p style={{ whiteSpace: "pre-wrap" }}>
        We reserve the right to modify the content of this site from time to
        time.
      </p>
      <h3 style={{ whiteSpace: "pre-wrap" }}>
        <strong>Anonymous data</strong>
      </h3>
      <p style={{ whiteSpace: "pre-wrap" }}>
        We use technology to collect anonymous information about the use of our
        website, for example when you browse our website our service provider
        logs your server address, the date and time of your visit, the pages and
        links accessed and the type of browser used. It does not identify you
        personally and we only use this information for statistical purposes and
        to improve the content and functionality of our website, to better
        understand our clients and markets and to improve our services.{" "}
      </p>
      <h3 style={{ whiteSpace: "pre-wrap" }}>
        <strong>Cookies</strong>
      </h3>
      <p style={{ whiteSpace: "pre-wrap" }}>
        In order to collect this anonymous data we may use “cookies”. Cookies
        are small pieces of information which are sent to your browser and
        stored on your computer’s hard drive. Sometimes they identify users
        where the website requires information to be retained from one page to
        the next. This is purely to increase the functionality of the site.
        Cookies by themselves cannot be used to discover the identity of the
        user. Cookies do not damage your computer and you can set your browser
        to notify you when you receive a cookie so that you can decide if you
        want to accept it. Once you leave the site, the cookie is destroyed and
        no personal or other information about you is stored.{" "}
      </p>
      <h3 style={{ whiteSpace: "pre-wrap" }}>
        <strong>Forms</strong>
      </h3>
      <p style={{ whiteSpace: "pre-wrap" }}>
        Our Website allows visitors to submit information via Self-Service forms
        (Claim Forms, Employment and Contact request). The information submitted
        via the Forms is not encrypted – an option is available for claim forms
        to be downloaded in PDF format for faxing. Should you be concerned about
        confidentiality of the claim information, this would be the recommended
        method.{" "}
      </p>
      <p style={{ whiteSpace: "pre-wrap" }}>
        Information collected via on-line forms is sent to our offices via EMAIL
        (not encrypted) and is also stored on a database which is accessible by
        Finstead Risk Solutions Pty Ltd staff only (password protected).{" "}
      </p>
      <p style={{ whiteSpace: "pre-wrap" }}>
        We also use your information to send you requested product information
        and promotional material and to enable us to manage your ongoing
        requirements, e.g. renewals, and our relationship with you, e.g.
        invoicing, client surveys etc.{" "}
      </p>
      <p style={{ whiteSpace: "pre-wrap" }}>
        We may occasionally notify you direct marketing about new services and
        special offers, events or articles we think will be of interest to you.
        We may send you regular updates by email or by post on insurance
        matters. If you would rather not receive this information or do not wish
        to receive it electronically, email or write to us.{" "}
      </p>
      <p style={{ whiteSpace: "pre-wrap" }}>
        We may use your information internally to help us improve our services
        and help resolve any problems.
      </p>
      <h3 style={{ whiteSpace: "pre-wrap" }}>
        <strong>Tell us what you think</strong>
      </h3>
      <p style={{ whiteSpace: "pre-wrap" }}>
        We welcome your questions and comments about privacy. If you have any
        concerns or complaints, please contact our Privacy Officer James
        Sinclair on telephone number +611300906963 or by email
        privacy@finsteadrs.com.au.
      </p>

      <p style={{ whiteSpace: "pre-wrap" }}>
        This Privacy Policy was last updated on&nbsp;
        <strong>10 July 2024</strong>.
      </p>
      <p
        className
        data-rte-preserve-empty="true"
        style={{ whiteSpace: "pre-wrap" }}
      />
      <p
        className
        data-rte-preserve-empty="true"
        style={{ whiteSpace: "pre-wrap" }}
      />
      <br />
      <br />
    </div>
  );
};

export default PrivacyPolicy;
