import React from "react";
import InnerPageBanner from "components/inner_page_banner";
import PrivacyPolicy from "components/privacy_policy";

const PrivacyPage = () => {
  return (
    <>
      <InnerPageBanner title={"Our Privacy Policy"} />
      <PrivacyPolicy />
    </>
  );
};

export default PrivacyPage;
